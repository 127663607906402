export const themes = {
  light: {
    colors: {
      one: "#f5f5f5",
      two: "#5B99C2FF",
      three: "#0d7ffc",
      four: "#1F316F",
      five: "#e0e0e0",      // Light grey for dividers
      six: "#0088cc",    // Same as primary for selected or highlighted items
      text: "#070707FF",
      background: "#f8fafc",
      headerBackground: "#ffffff",
      componentBackground: "white",
      border : "#5B99C2",
      messageIn:"#6b7280",
      messageOut:"#0088cc",
      text1: "#6b7280",
      text2: "#4b5563",
      text3: "#f5f5f5",
      hover: "#e0e0e0",
      buttonHover: "#162c46",
      selected: "#ACB5C7FF",
      cancel: "#FF0000",    
    },
    padding: {
      small: "8px",
      medium: "16px",
      large: "32px",
    },
    fontSizes: {
      small: "12px",
      medium: "16px",
      large: "24px",
    },
    borderRadius: {
      small: "4px",
      medium: "8px",
      large: "16px",
    },
    fontFamily: "FARegular, sans-serif", // Default font for the light theme
    fontFamilyBold: "FASolid, sans-serif", // Bold font for the light theme
    fontFace: `
      @font-face {
        font-family: 'FARegular';
        src: url('/fonts/fa-regular-400.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: 'FASolid';
        src: url('/fonts/fa-solid-900.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
      }
    `,
  },
  dark: {
    colors: {
      one: "#162c46",
      two: "#9badc6",
      three: "#9badc6",
      four: "#EEEEEE",
      five: "#601a2b",      // Light grey for dividers
      six: "#0088cc",    // Same as primary for selected or highlighted items
      text: "#f5f5f5",
      background: "#162c46",
      headerBackground: "#162c46",
      componentBackground: "#0d2136",
      border : "#9badc6",
      messageIn:"#374151",
      messageOut:"#0088cc",
      text1: "#6b7280",
      text2: "#4b5563",
      text3: "#0088cc",
      hover: "#162c46",
      buttonHover: "#162c46",
      selected: "#f5f5f5",
      cancel: "#FF0000", 
    },
    padding: {
      small: "8px",
      medium: "16px",
      large: "32px",
    },
    fontSizes: {
      small: "12px",
      medium: "16px",
      large: "24px",
    },
    borderRadius: {
      small: "4px",
      medium: "8px",
      large: "16px",
    },
    fontFamily: "FARegular, sans-serif", // Default font for the dark theme
    fontFamilyBold: "FASolid, sans-serif", // Bold font for the dark theme
    fontFace: `
      @font-face {
        font-family: 'FARegular';
        src: url('/fonts/fa-regular-400.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: 'FASolid';
        src: url('/fonts/fa-solid-900.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
      }
    `,
  },
  depth: {
    colors: {
      // one: "#ffffff",   // Light grey background
      // two: "#b92ac5",      // Light blue for header and icons
      one: "#c8b4f680",   // Light grey background
      two: "#ffffff",      // Light blue for header and icons
      twopointfive: "#9a71ee", // use this to create contrast try this idea
      three: "#000000",         // Dark grey/black for primary text
      four: "#888888", // Lighter grey for secondary text
      five: "#e0e0e0",      // Light grey for dividers
      six: "#0088cc",    // Same as primary for selected or highlighted items
      background: "#162c46",
      headerBackground: "#0d2136",
      componentBackground: "#0d2136",
      border : "#5B99C2",
      messageIn:"#0d2136",
      messageOut:"#031525",
      text: "#f5f5f5",
      text1: "#000000",
      text2: "#888888",
      text3: "#f5f5f5",
      hover: "#601a2b",
      selected: "#5B99C2",
      cancel: "#FF0000", 
    },
    padding: {
      small: "8px",
      medium: "16px",
      large: "32px",
    },
    fontSizes: {
      small: "12px",
      medium: "16px",
      large: "24px",
    },
    borderRadius: {
      small: "4px",
      medium: "8px",
      large: "16px",
    },
    fontFamily: "FARegular, sans-serif", 
    fontFamilyBold: "FASolid, sans-serif",
    fontFace: `
      @font-face {
        font-family: 'FARegular';
        src: url('public/chat-assets/fonts/fa-regular-400.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: 'FASolid';
        src: url('public/chat-assets/fonts/fa-solid-900.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
      }
    `,
  },
  
};

